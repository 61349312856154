/** @jsx jsx */
/**
 * Module dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import commonStyles, { IconStyled } from "./commons"

const ButtonStyled = styled.button`
  ${({ theme, size }) => commonStyles(theme, size)}
`

/**
 * Button
 */
const Button = ({ children, icon, ...buttonProps }) => {
  return (
    <ButtonStyled {...buttonProps}>
      {children}
      <IconStyled>{icon}</IconStyled>
    </ButtonStyled>
  )
}

/**
 * PropTypes definitions
 */
Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.instanceOf(null)]),
  size: PropTypes.oneOf(["small", "default"]),
}

Button.defaultProps = {
  children: null,
  icon: null,
  size: "default",
}

/**
 * Expose module
 */
export default Button
