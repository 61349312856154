/**
 * Module dependencies
 */
import { css, keyframes } from "@emotion/core"
import { rgba } from "emotion-rgba"
import styled from "@emotion/styled"

const bounce = keyframes`
  0% {
    transform: translate3d(0px, 0, 0);
  }

  50% {
    transform: translate3d(5px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
`

const commonStyles = (theme, size) => css`
  background-color: ${theme.colors.primary};
  border: 0;
  border-radius: ${theme.miscellaneous.borderRadius};
  color: ${theme.colors.background};
  font-size: ${theme.fontSizes.xxl};
  cursor: pointer;
  font-weight: ${theme.fontWeights.medium};
  min-width: 280px;
  outline: 0;
  padding: ${theme.gutters.m};
  transition: background-color 0.1s ease-in;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${rgba(theme.colors.primary, 0.8)};

    svg {
      animation: ${bounce} 0.6s ease-out infinite;
    }
  }

  &:active {
    background-color: ${rgba(theme.colors.primary, 0.6)};
  }

  ${size === "small" &&
  `
    font-size: ${theme.fontSizes.base};
    padding: ${theme.gutters.base};
    min-width: 200px;
`}
`

export const IconStyled = styled.span`
  display: flex;
  padding-left: ${({ theme }) => theme.gutters.s};
  svg {
    width: 1em;
    height: 1em;
  }

  path {
    fill: ${({ theme }) => theme.colors.background};
    transition: fill 0.1s ease-in;
  }
`

export default commonStyles
