/** @jsx jsx */
/**
 * Module dependencies
 */
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"
import commonStyles, { IconStyled } from "./commons"

const LinkStyled = styled.a`
  ${({ theme, size }) => commonStyles(theme, size)}
`
const Alink = ({ children, icon, ...linkProps }) => (
  <LinkStyled {...linkProps}>
    {children}

    <IconStyled>{icon}</IconStyled>
  </LinkStyled>
)

/**
 * Expose module
 */
export default Alink
